import { render, staticRenderFns } from "./filtros.vue?vue&type=template&id=2d1e5954&scoped=true&"
import script from "./filtros.vue?vue&type=script&lang=js&"
export * from "./filtros.vue?vue&type=script&lang=js&"
import style0 from "./filtros.vue?vue&type=style&index=0&id=2d1e5954&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d1e5954",
  null
  
)

export default component.exports