<template>
  <div class="filters-content">
    <div class="filters">
      <span>Filtrar por:</span>
      <div class="select is-small">
        <select @change="changeFilter" v-model="filterType">
          <option :value="null">Seleccione</option>
          <option value="all">Ver todo</option>
          <option value="vacante">Vacante</option>
        </select>
      </div>

      <div class="select is-small" v-if="filterType === 'vacante'">
        <select @change="selectVacante">
          <template v-for="vacante in vacantes">
            <option :key="vacante.id" :value="vacante.id">{{vacante.name}}</option>
          </template>
        </select>
      </div>
      <button
        v-if="this.filterType"
        class="button is-small is-success filter-buttom"
        :class="{'is-loading': loading}"
        @click="setFilter"
      >
        Filtrar
      </button>
    </div>
    <div class="filter-tags" v-if="filterTags.length > 0">
      <div class="tag is-success" v-for="(filter, key) in filterTags" :key="key">
        <label>{{filter.key}}</label>: {{setLabel(filter.value)}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'filters',
  props: {
    vacantes: {
      type: Array,
      default: () => []
    },
    getPostulantes: {
      type: Function,
      default: () => {}
    },
    close: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      filterType: null,
      filters: {},
      filterTags: []
    }
  },
  methods: {
    setLabel (id) {
      const label = this.vacantes.find(vacante => vacante.id === id)
      return label ? label.name : id
    },
    setFilterTags () {
      const objectArray = Object.entries(this.filters)
      const data = []
      objectArray.forEach(([key, value]) => {
        data.push({ key, value })
      })
      this.filterTags = data
    },
    changeFilter (el) {
      if (el.target.value === 'all') {
        this.filters = {}
      }
    },
    selectVacante (el) {
      this.filters = {
        ...this.filters,
        vacante: el.target.value
      }
      if (el.target.value === '') {
        delete this.filters.vacante
      }
    },
    async setFilter () {
      this.loading = true
      if (this.filters && Object.keys(this.filters).length === 0) {
        this.filters = {}
      }
      await this.getPostulantes(1, this.filters || '')
      this.loading = false
      this.filterType = null
      this.setFilterTags()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  span {
    font-size: 12px;
    margin-right: 0.5rem;
  }
  .select {
    margin-left: 0.5rem;
  }
}
.filter-buttom {
  border-radius: 5px !important;
  margin-left: 5px;
}
.filter-tags {
  display: flex;
  justify-content: flex-start;
}
</style>
